
















































































































import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
  props: {},
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Privacy Policy for the Bitcoin Games online casino - GDPR Compliant, Retention, Transfer & Security of Your Data`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Bitcoin Cash and Bitcoin Games`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
})
@Component({
  components: {}
})
export default class Tos extends AppProps {
}
